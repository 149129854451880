export const KeySwitch = (props) => (
  <svg
    viewBox="0 0 163 265"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    {...props}
  >
    <path
      d="m459.669 367.484.488 18.695-3.726 14.371M403.537 394.796s-.872 18.591-2.497 18.916M426.947 377.726l.162 26.986 21.785-6.99 2.926-9.754-.162-17.233M419.631 380.002s-.325 33.326-2.276 33.977M344.849 363.257l6.503 4.227 8.128-3.252M392.157 395.933l-.975 22.76M373.136 373.174l-.487 9.591 19.345 13.168 14.794-2.275-.65-8.779"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.33px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="M338.184 349.276s55.169 37.714 56.737 37.391c23.561-4.86 88.6-28.125 88.6-28.125M455.943 351.552l16.419-5.69M395.467 343.423l-37.554-23.897"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.33px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="M395.205 308.774s-28.808 5.89-36.871 10.104c-1.728.904-2.254 5.055-2.99 9.826-1.028 6.67-2.496 15.649-2.496 15.649l-4.878-4.747-6.706 8.941-6.299 1.219v7.113l9.551 6.096 2.845 23.573 47.348 35.156 5.487-1.423.709-6.498s6.821 2.051 10.519 1.577c9.364-1.202 44.859-14.181 44.859-14.181l13.892-6.133 6.872-.176 2.392-22.907 9.957-4.924.186-6.299-6.679-3.251-4.877-9.108-6.013-3.012-18.095-20.071-31.119-18.015"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.33px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="M453.918 325.298s-46.433 12.44-57.834 18.064c-2.649 1.308-5.413 25.867-5.413 25.867l6.04 4.585 23.497-6.033-8.131-30.312"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.33px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="m449.466 326.508 7.452 31.547 21.947-7.316M410.461 337.993l.612 24.776-18.532 5.365M394.978 313.936l-12.694 3.538 25.179 15.086 32.774-8.636-17.584-11.132"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.33px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="m407.509 298.812 5.02 2.965-.04 23.346.04-23.346 5.671-.498.138 18.73M418.114 301.151l-4.418-3.191 8.139-1.96M398.905 300.086l8.547-1.305.2 23.455"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: ".65px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="m400.107 293.675 4.661 3.462-8.723 1.598-.133 23.639 2.597 1.399 9.122-1.665 5.061 3.329 5.527-.932.133-4.661 3.463-.2.024-23.663-2.975-1.755-8.534 1.761-3.897-2.779-6.326.467Z"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: ".65px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="m396.045 298.735 2.86 1.457-.213 23.439M400.008 293.709v4.03"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: ".65px",
      }}
      transform="translate(-330 -160)"
    />
    <path
      d="M404.523 166.484a9.092 9.092 0 0 1 10.879.721c5.179 4.513 13.302 11.322 19.337 15.218 9.694 6.259 33.087 16.665 33.087 16.665l-57.915 37.797s-25.827-8.885-35.784-14.711c-9.68-5.663-23.757-20.444-23.757-20.444l54.153-35.246Z"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.76px",
      }}
      transform="translate(-332.778 -152.713)"
    />
    <path
      d="m409.911 236.885-6.734 38.987a1.69 1.69 0 0 0 .512 1.526c.417.387 1 .534 1.548.389l76.613-20.234a7.393 7.393 0 0 0 4.765-3.945 7.522 7.522 0 0 0 .138-6.223l-20.628-48.133"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.7px",
      }}
      transform="matrix(1.02837 0 0 1.01799 -344.03 -156.296)"
    />
    <path
      d="M403.554 278.967s-21.422-10.757-38.122-21.15c-7.767-4.833-21.162-14.381-26.925-18.526a4.322 4.322 0 0 1-1.477-5.149l13.25-32.334"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2.76px",
      }}
      transform="translate(-332.778 -152.713)"
    />
    <text
      x={395.973}
      y={195.672}
      style={{
        fontFamily: "'ArialMT','Arial',sans-serif",
        fontSize: "22.718px",
        stroke: "white",
        strokeWidth: "1.44px",
        strokeLinecap: "butt",
        strokeMiterlimit: 2,
      }}
      transform="matrix(.50601 .44425 -.46928 .53452 -41.692 -254.301)"
    >
      {"ESC"}
    </text>
  </svg>
);

export const Switch = (props) => (
  <svg
    viewBox="0 0 160 136"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    {...props}
  >
    <path
      d="m459.669 367.484.488 18.695-3.726 14.371M403.537 394.796s-.872 18.591-2.497 18.916M426.947 377.726l.162 26.986 21.785-6.99 2.926-9.754-.162-17.233M419.631 380.002s-.325 33.326-2.276 33.977M344.849 363.257l6.503 4.227 8.128-3.252M392.157 395.933l-.975 22.76M373.136 373.174l-.487 9.591 19.345 13.168 14.794-2.275-.65-8.779"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.33px",
      }}
      transform="translate(-332 -290)"
    />
    <path
      d="M338.184 349.276s55.169 37.714 56.737 37.391c23.561-4.86 88.6-28.125 88.6-28.125M455.943 351.552l16.419-5.69M395.467 343.423l-37.554-23.897"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.33px",
      }}
      transform="translate(-332 -290)"
    />
    <path
      d="M395.205 308.774s-28.808 5.89-36.871 10.104c-1.728.904-2.254 5.055-2.99 9.826-1.028 6.67-2.496 15.649-2.496 15.649l-4.878-4.747-6.706 8.941-6.299 1.219v7.113l9.551 6.096 2.845 23.573 47.348 35.156 5.487-1.423.709-6.498s6.821 2.051 10.519 1.577c9.364-1.202 44.859-14.181 44.859-14.181l13.892-6.133 6.872-.176 2.392-22.907 9.957-4.924.186-6.299-6.679-3.251-4.877-9.108-6.013-3.012-18.095-20.071-31.119-18.015"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.33px",
      }}
      transform="translate(-332 -290)"
    />
    <path
      d="M453.918 325.298s-46.433 12.44-57.834 18.064c-2.649 1.308-5.413 25.867-5.413 25.867l6.04 4.585 23.497-6.033-8.131-30.312"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.33px",
      }}
      transform="translate(-332 -290)"
    />
    <path
      d="m449.466 326.508 7.452 31.547 21.947-7.316M410.461 337.993l.612 24.776-18.532 5.365M394.978 313.936l-12.694 3.538 25.179 15.086 32.774-8.636-17.584-11.132"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.33px",
      }}
      transform="translate(-332 -290)"
    />
    <path
      d="m407.509 298.812 5.02 2.965-.04 23.346.04-23.346 5.671-.498.138 18.73M418.114 301.151l-4.418-3.191 8.139-1.96M398.905 300.086l8.547-1.305.2 23.455"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: ".65px",
      }}
      transform="translate(-332 -290)"
    />
    <path
      d="m400.107 293.675 4.661 3.462-8.723 1.598-.133 23.639 2.597 1.399 9.122-1.665 5.061 3.329 5.527-.932.133-4.661 3.463-.2.024-23.663-2.975-1.755-8.534 1.761-3.897-2.779-6.326.467Z"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: ".65px",
      }}
      transform="translate(-332 -290)"
    />
    <path
      d="m396.045 298.735 2.86 1.457-.213 23.439M400.008 293.709v4.03"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: ".65px",
      }}
      transform="translate(-332 -290)"
    />
  </svg>
);

export const Key = (props) => (
  <svg
    viewBox="0 0 157 127"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    {...props}
  >
    <path
      d="M404.523 166.484a9.092 9.092 0 0 1 10.879.721c5.179 4.513 13.302 11.322 19.337 15.218 9.694 6.259 33.087 16.665 33.087 16.665l-57.915 37.797s-25.827-8.885-35.784-14.711c-9.68-5.663-23.757-20.444-23.757-20.444l54.153-35.246Z"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.76px",
      }}
      transform="translate(-333.778 -154.713)"
    />
    <path
      d="m409.911 236.885-6.734 38.987a1.69 1.69 0 0 0 .512 1.526c.417.387 1 .534 1.548.389l76.613-20.234a7.393 7.393 0 0 0 4.765-3.945 7.522 7.522 0 0 0 .138-6.223l-20.628-48.133"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.7px",
      }}
      transform="matrix(1.02837 0 0 1.01799 -345.03 -158.296)"
    />
    <path
      d="M403.554 278.967s-21.422-10.757-38.122-21.15c-7.767-4.833-21.162-14.381-26.925-18.526a4.322 4.322 0 0 1-1.477-5.149l13.25-32.334"
      style={{
        fill: "none",
        stroke: "inherit",
        strokeWidth: "2.76px",
      }}
      transform="translate(-333.778 -154.713)"
    />
    <text
      x={395.973}
      y={195.672}
      style={{
        fontFamily: "'ArialMT','Arial',sans-serif",
        fontSize: "22.718px",
        stroke: "inherit",
        strokeWidth: "1.44px",
        strokeLinecap: "butt",
        strokeMiterlimit: 2,
      }}
      transform="matrix(.50601 .44425 -.46928 .53452 -42.692 -256.301)"
    >
      {"ESC"}
    </text>
  </svg>
);

export const Jupiter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      height: 512,
      width: 512,
    }}
    {...props}
  >
    <circle cx={256} cy={256} r={256} fill="inherit" />
    <path
      d="M256.175 32A224 224 0 0 0 156.11 55.68h199.71A224 224 0 0 0 256.174 32zM104.247 91.68a224 224 0 0 0-30.668 34.84l355.482-12.694a224 224 0 0 0-21.065-22.146h-303.75zM54.093 160a224 224 0 0 0-6.987 16.303l420.797 7.41a224 224 0 0 0-9.59-23.713H54.093zm-13.037 34.2a224 224 0 0 0-6.168 28.894l441.386-8.024a224 224 0 0 0-2.976-13.257L41.056 194.2zm437.693 38.827L32.92 241.13a224 224 0 0 0-.745 14.87 224 224 0 0 0 1.43 23h251.5c-3.19 1.413-6.214 3.02-9.024 4.816-5.576 3.568-10.425 8.035-14.005 13.184H36.09a224 224 0 0 0 7.93 30h213.44c3.102 9.023 10.002 16.672 18.62 22.184 3.162 2.023 6.593 3.802 10.235 5.336l-225.2 11.058a224 224 0 0 0 11.017 17.67l359.785 11.244a224 224 0 0 0 29.268-48.56l-99.652 4.894c.93-.53 1.844-1.074 2.733-1.642 8.615-5.512 15.516-13.16 18.618-22.184h85.53a224 224 0 0 0 7.82-30H378.27c-3.58-5.15-8.428-9.616-14.005-13.184a67.432 67.432 0 0 0-9.027-4.816H478.96a224 224 0 0 0 .865-15.945l-75.808-10.918s48.452-4.13 75.54-6.344a224 224 0 0 0-.808-12.766zM320.174 290c13.77 0 26.1 3.674 34.394 8.98 8.292 5.303 12.105 11.52 12.105 17.52s-3.813 12.217-12.106 17.52c-8.294 5.306-20.625 8.98-34.395 8.98-13.77 0-26.102-3.674-34.395-8.98-8.293-5.303-12.105-11.52-12.105-17.52s3.812-12.217 12.105-17.52c8.293-5.306 20.624-8.98 34.395-8.98zM96.493 413a224 224 0 0 0 18.897 17h281.693a224 224 0 0 0 18.59-17H96.493zm82.537 53a224 224 0 0 0 77.145 14 224 224 0 0 0 77.488-14H179.03z"
      fill="inherit"
    />
  </svg>
);
